<template>
  <div class="h-100vh position-relative bg-light-gray">
    <div class="logo-image-container text-center complain-header py-3">
      <img src="@/assets/logo-d-dot.svg" class="logo-img" />
    </div>
    <div class="d-flex justify-content-center w-100 complain-container">
      <div class="w-100 my-3">
        <div class="d-flex justify-content-center">
          <div v-if="result > -1" class="card p-5 mx-3 session-card">
            <div v-if="result" class="text-center f-size-22 font-weight-bold">
               สำเร็จ
            </div>
            <div v-else class="text-center f-size-22 font-weight-bold">
               {{ modalMessage || 'ไม่สำเร็จ' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hadleModalAlertClose="hadleModalAlertClose"
    />
    <ModalAlertError
      ref="modalAlertError"
      :text="modalMessage"
    />
  </div>
</template>

<script>
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";

export default {
  components: {
    ModalLoading,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      modalMessage: "",
      result: -1
    };
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      await this.$liff
        .init({ liffId: this.$liff_Agent_QR_Code })
        .then(async () => {
          await this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
          await this.submit();
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  methods: {
    hadleModalAlertClose() {
      if (this.$liff.isInClient()) {
        this.$liff.closeWindow();
      } else {
        this.$router.push("/success/complain");
      }
    },
    submit: async function () {
      this.$refs.modalLoading.show();
      let body = {
        SocialReferenceId: this.lineProfile.userId,
        AgentGUID: this.$route.query.guid,
        PlatFormId: 1,
        BrandId: this.$route.query.brandId
      }
      await this.$axios
        .post(`${this.$baseUrl}/form/agent-fix-customer`, body)
        .then(data => {
          if (data.data.result == 1) {
            if (this.$liff.isInClient()) {
              this.modalMessage = this.$t("messageDoneSuccess");
              this.$refs.modalLoading.hide();
              this.$refs.modalAlert.show();
              setTimeout(() => {
                this.hadleModalAlertClose();
              }, 1000);
            } else {
              this.modalMessage = this.$t("messageDoneSuccess");
              this.$refs.modalAlert.show();
              setTimeout(() => {
                window.close();
              }, 1000);
            }
          } else {
            this.modalMessage = data.data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.text-error {
  color: #dc3545 !important;
  font-weight: bold;
  font-size: 16px;
}

.complain-container {
  height: calc(100vh - 60.5px);
}

::v-deep .card {
  border-color: white;
  border-radius: 14px;
}
@media only screen and (min-width: 767.98px) {
  .text-error {
    font-size: 15px;
  }
}
</style>
